import "../utils/self-node-hack"
import styled from "@emotion/styled"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import { useFeaturedImages } from "../hooks/use-featured-images"
import { getTranslateXY } from "../utils/dom"
import { Link } from "./basic"

const ImageLink = styled(Link)`
  display: block;
  background-image: none;
  max-height: 420px;
  height: 420px;

  .gatsby-image-wrapper {
    height: 420px;
    img {
      width: auto;
      max-height: 420px;
    }
  }
`

function resetSliderOffset(slider) {
  const sliderDomElem = slider.splide.root
  const listElem = sliderDomElem.querySelector(".splide__list")
  const { translateX } = getTranslateXY(listElem)

  if (translateX > 0) {
    listElem.style.transform = "translateX(0)"
  }
}

export function FeaturedSlider() {
  const images = useFeaturedImages()
  const slider = useRef()

  useEffect(() => {
    resetSliderOffset(slider.current)
  }, [])

  return (
    <Splide
      ref={slider}
      options={{
        perMove: 3,
        autoWidth: true,
        rewind: true,
        focus: "center",
        trimSpace: true,
        pagination: false,
        breakpoints: {
          640: {
            perMove: 2,
          },
          500: {
            perMove: 1,
          },
        },
      }}
    >
      {images.map(({ id, image, postSlug, postTitle }) => (
        <SplideSlide key={id}>
          <ImageLink to={`${postSlug}#main-results`}>
            <GatsbyImage image={image} alt={postTitle} draggable="false" />
          </ImageLink>
        </SplideSlide>
      ))}
    </Splide>
  )
}
