import { useStaticQuery, graphql } from "gatsby"

export const useFeaturedImages = () => {
  const {
    allFile: { images },
    allMdx: { posts },
  } = useStaticQuery(graphql`
    query FeaturedImagesQuery {
      allFile(
        filter: { absolutePath: { regex: "/content/posts.+(jpg|png)/" } }
      ) {
        images: nodes {
          id
          base
          absolutePath
          childImageSharp {
            original {
              width
              height
            }
            image: gatsbyImageData(
              height: 630 # 420 * 1.5
              transformOptions: { fit: FILL }
              placeholder: BLURRED
            )
          }
        }
      }
      allMdx(
        filter: {
          fields: { visible: { eq: true }, fileSourceName: { eq: "posts" } }
        }
      ) {
        posts: nodes {
          fields {
            slug
            lastModified
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  `)

  const postData = posts.reduce(
    (acc, { fields: { slug, lastModified }, frontmatter: { title } }) => ({
      ...acc,
      [slug]: { title, date: lastModified },
    }),
    {}
  )

  return images
    .map(({ id, childImageSharp, absolutePath, base }) => {
      const [, slug] = absolutePath.match(
        new RegExp(`.+/posts/([-a-zA-Z0-9]+)/${base}`)
      )

      const { title, date } = postData[slug] || {}

      const {
        image,
        original: { width, height },
      } = childImageSharp

      return {
        image,
        width,
        height,
        id: `${slug}-${id}`,
        postSlug: slug,
        postTitle: title,
        postTimestamp: new Date(date).getTime(),
      }
    })
    .filter(({ width, height, postTitle }) => postTitle && height > width)
    .sort((a, b) => b.postTimestamp - a.postTimestamp)
}
