import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Seo } from "gatsby-theme-grape-blog/src/components/seo"
import React from "react"
import Bio from "../components/bio"
import { FeaturedSlider } from "../components/featured-slider"
import { Header } from "../components/header"
import Layout, { Container, Main, OneColumn } from "../components/layout"
import { MailchimpPopup } from "../components/mailchimp-popup"
import { PatreonButton } from "../components/patreon-button"
import { PostsList } from "../components/posts-list"
import { ViewAllTagsButton } from "../components/taxonomy"
import { toPosts } from "../utils/schema"
import { theme } from "../utils/theme"

const StyledBio = styled(Bio)`
  margin: 0;
  flex-direction: row;

  a {
    font-size: 90%;
    margin-left: 0.5rem;
  }
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  p {
    font-family: ${theme.fonts.header.fontFamily};
    font-style: italic;
    margin-bottom: 0.5rem;
  }
`

const BlogIndex = ({ data }) => {
  const posts = toPosts(data.allMdx.edges)

  return (
    <Layout>
      <MailchimpPopup />
      <Seo />
      <Header>
        <Hero>
          <p>bringing you weightlifting research in a digestible form</p>
          <StyledBio
            content={({ author }) => (
              <a href={author.social.instagram}>@evidence_strong</a>
            )}
          />
          <PatreonButton />
        </Hero>
      </Header>
      <FeaturedSlider />
      <Container>
        <ViewAllTagsButton isLandscape={true} text="Search by Tags" />
      </Container>
      <OneColumn>
        <Main>
          <PostsList posts={posts} />
        </Main>
      </OneColumn>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query AllPosts {
    allMdx(
      sort: { fields: [fields___lastModified], order: DESC }
      filter: {
        fields: { visible: { eq: true }, fileSourceName: { eq: "posts" } }
      }
    ) {
      edges {
        node {
          ...customPostDetails
        }
      }
    }
  }
`
