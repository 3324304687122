import React from "react"
import { Button } from "../components/basic"
import { FaPatreon } from "react-icons/fa"
import styled from "@emotion/styled"

const BrandedButton = styled(Button)`
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  color: #ffffff;
  border: solid 1px #e1000c;
  background-color: #ff424d;

  span {
    line-height: 1;
    white-space: nowrap;
  }

  &:hover {
    background-color: #e1000c;
    span {
      background-image: none;
    }
  }
`

export const PatreonButton = () => (
  <BrandedButton to="https://www.patreon.com/evidencestrong" icon={FaPatreon}>
    More on Patreon!
  </BrandedButton>
)
