import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const UpdatedBadge = () => {
  return (
    <StaticImage
      src="../images/updated-badge.png"
      alt="Updated"
      placeholder="blurred"
      layout="fixed"
      width={80}
    />
  )
}
